/* Sticky footer styles
-------------------------------------------------- */
/* Set the fixed height of the footer here */
@footer-height:     40px;

html, body {
    height: 100%;
    /* The html and body elements cannot have any padding or margin. */
}

/* Wrapper for page content to push down footer */
#wrap {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    /* Negative indent footer by its height */
    margin: 0 auto -@footer-height;
    /* Pad bottom by footer height */
    padding: 0 0 @footer-height;
}

/* Footer styles
-------------------------------------------------- */
#footer {
    height: @footer-height;
    background-color: #f5f5f5;
}

.container-fluid .credit {
    padding-top: 0px;
    margin-top: 8px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

#socialBar .credit a {
    padding-right: 10px;
    color: @text-muted;
}

#socialBar .credit a:hover {
    color: @link-color;
    text-decoration: none;
}

#footer table {
    width: 100%;
    table-layout: fixed /* Give table columns equal width */
}