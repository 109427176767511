/* Group hover styles
-------------------------------------------------- */
.group_logo {
    position: relative;
    overflow: hidden;
    width: 150px;
}

.overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: none;
    text-align: center;
    color: #0C1621 !important;
    z-index: 2;
}

// .caption {
//     position: absolute;
//     top: 40px;
//     right: 0;
//     width: 100%;
//     height: 100%;
//     display: none;
//     text-align: center;
//     color: #0C1621 !important;
//     z-index: 3;
// }

/* Group scrolling styles
-------------------------------------------------- */
.scroll-horizontal {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
}

.list-inline {
    padding: 0px 0px;
}

.list-inline {
    white-space: nowrap;
}

.panel-scroll {
    padding-left: 0px;
    padding-right: 0px;
}