/* Branding styles
-------------------------------------------------- */
//== Colors
//
//## Brand colors for use across the site.

@SH-navy-blue:              #0C1D2B;
@SH-colored-gray:           #BACED4;
@SH-colored-gray-dark:      #7F9BA5;
@SH-brown:                  #575656;
@SH-yellow:                 #E8C24B;
@SH-white:                  #FAFAF7;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.
@import url(https://fonts.googleapis.com/css?family=Oswald:400,700,300);
@import url(https://fonts.googleapis.com/css?family=Inconsolata);

@SH-font-main:              'Oswald';    
@SH-font-monospace:         'Inconsolata';

//== Lists
//
//## Own list elements.
ul.starred {
    list-style: url("../img/ster.png");
}