@charset "UTF-8";

@font-face {
  font-family: "social";
  src:url("../fonts/social.eot");
  src:url("../fonts/social.eot?#iefix") format("embedded-opentype"),
    url("../fonts/social.woff") format("woff"),
    url("../fonts/social.ttf") format("truetype"),
    url("../fonts/social.svg#social") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "social" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "social" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-youtube:before {
  content: "a";
}
.icon-google-plus:before {
  content: "b";
}
.icon-linkedin-square:before {
  content: "c";
}
.icon-linkedin:before {
  content: "d";
}
.icon-facebook:before {
  content: "e";
}
.icon-facebook-box:before {
  content: "f";
}
.icon-twitter:before {
  content: "g";
}
.icon-youtube-box:before {
  content: "h";
}
