@media print {
	.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
	  float: left!important;
	  width: 100% !important;;
	}
	
	html, body {
	    height: 100% !important;;
	    margin: 0 !important;;
	    padding: 0 !important;;
	}
	
	#wrap {
	    height: 100% !important;;
	    margin: 0 !important;;
	    padding: 0 !important;;
	}
	
	.breadcrumb a[href]:after {
    	content: none;
  	}
}